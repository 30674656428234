@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Arvo:ital,wght@0,400;0,700;1,400;1,700&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    background: #19191A;
}

li a.active {
    color: #C9A254;
    font-weight: 700;
}

.slick-slider {
    margin: 30px auto 50px !important;
    width: auto !important;
}

.slick-slide {
    justify-content: center;
    padding: 16px 16px;
    /* background: #000;
  Atur padding kiri dan kanan */
    box-sizing: border-box;
    margin: 0 0;
    /* Pastikan padding tidak mempengaruhi ukuran elemen */
}

.slick-dots li {
    margin: 0 16px !important;
}

.slick-dots li button:before {
    content: ' ' !important;
    background: #f5f5f5 !important;
    margin-left: 10px !important;
    opacity: 1;
    height: 4px !important;
    width: 40px !important;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.slick-dots li.slick-active button:before {
    content: ' ';
    background: #f5f5f5;
    opacity: 1;
    height: 4px;
    width: 40px;
}

* {
    font-family: 'Inter', sans-serif;
}

.custom-popup {
    padding: 10px;
    font: antialiased;
    color: #f5f5f5;
    text-align: left;
    background: rgba(49, 49, 53, 0.84);
    border-radius: 12px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(9.1px);
    -webkit-backdrop-filter: blur(9.1px);
    border: 1px solid rgba(49, 49, 53, 0.22);
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.custom-swal {
    color: #f5f5f5;
    background: rgba(49, 49, 53, 0.84);
    border-radius: 12px;
    background: rgba(32, 32, 34, 0.94);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(9.8px);
    -webkit-backdrop-filter: blur(9.8px);
    border: 1px solid rgba(32, 32, 34, 0.23);
}

.bg-navbar {
    background: rgba(49, 49, 49, 0.92);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(6.7px);
    -webkit-backdrop-filter: blur(6.7px);
    border: 1px solid rgba(49, 49, 49, 0.48);
}
